<template>
	<v-app>
		<main class="fullh">
			<v-container fluid fill-height class="loginOverlay">
				<v-layout flex align-center justify-center>
					<v-flex xs12 sm4>
						<div style="text-align: center;" class="mb-2">
							<img src="../assets/logo-sc-blanco.svg" />
						</div>
						<v-card elevation="0" shaped>
							<v-card-text class="pt-4">
								<div>
									<h2 class="headline mb-5 text-align-center">
										{{ $t('forgot.txtTitle') }}
									</h2>
									<v-form v-model="valid" ref="form">
										<v-text-field
											v-if="!returnLogin"
											:label="$t('global.emailRecover')"
											v-model="email"
											:rules="emailRules"
											required
											outlined
											dense
										></v-text-field>
										<v-btn
											block
											color="deep-purple accent-4"
											dark
											v-else
											@click.prevent="login"
											>{{$t('forgot.btnReturnToLogin')}}</v-btn
										>
										<v-layout
											justify-space-between
											class="pt-3"
										>
											<v-spacer></v-spacer>
											<v-btn
												v-if="!returnLogin"
												@click="submit"
												:class="{
													'deep-purple accent-4 white--text': valid,
												}"
												tile
												:loading="loading"
												color="deep-purple accent-4"
												:disabled="!valid || disabled"
												>{{ $t('forgot.btnSendEmail') }}</v-btn
											>
										</v-layout>
									</v-form>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</main>

		<v-snackbar v-model="snackbar" color="primary" shaped>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="snackbar = false">
					{{ $t('global.snackbar.close') }}
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
export default {
	data() {
		return {
			valid: false,
			e1: false,
			text: this.$t('forgot.txtEmaildSend'),
			snackbar: false,
			email: "",
			emailRules: [
				(v) => !!v || this.$t('global.rules.emailRequired'),
				(v) =>
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
					this.$t('global.rules.emailValid'),
			],
			loading: false,
			disabled: false,
			returnLogin: false,
		};
	},
	methods: {
		submit(e) {
			/* this.$router.push({name: 'Login'});
			if (this.$refs.form.validate()) {
				this.$refs.form.$el.submit();
			} */

			e.preventDefault();
			this.loading = true;
			this.disabled = true;
			this.$axios
				.post("/auth/reset-password", {
					email: this.email,
				})
				.then(() => {})
				.catch(() => {
					this.text = this.$t('forgot.txTunexpectedError');
				})
				.finally(() => {
					this.snackbar = true;
					this.returnLogin = true;
				});
		},
		clear() {
			this.$refs.form.reset();
		},
		login() {
			this.$router.push({ name: "Login" });
		},
	},
};
</script>

<style scoped>
.fullh {
	height: 100%;
	background-color: #4527a0;
}
.text-align-center {
	text-align: center;
}
</style>
